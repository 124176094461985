import React, { Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { RecoilRoot } from 'recoil'
import { ThemeProvider } from '@mui/material/styles'
import Loading from './components/irisComponents/helpers/Loading'
import theme from './components/style/theme'
import './i18n/index'

const acceptedLangs = ['ca', 'en', 'es', 'fr']
const defaultLanguage = 'ca'
const lang = localStorage.getItem('i18nextLng')

if (lang !== null) {
  if (!acceptedLangs.includes(lang)) {
    localStorage.setItem('i18nextLng', defaultLanguage)
  }
  // @ts-expect-error
  window.__localeId__ = lang
}

const container = document.getElementById('root') as Element
const root = createRoot(container)
root.render(
  <React.StrictMode>
    <Suspense fallback={<Loading />}>
      <RecoilRoot>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </RecoilRoot>
    </Suspense>
  </React.StrictMode>
)

reportWebVitals()
