import { createTheme } from '@mui/material/styles'
import { grey } from '@mui/material/colors'

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    disabled: Palette['primary']
  }
  interface PaletteOptions {
    disabled: PaletteOptions['primary']
  }
}

// MUI Theme can be overridden from config.js :P
// @ts-expect-error
const customTheme = window.CONFIG?.theme

const theme = createTheme(
  customTheme !== undefined
    ? customTheme
    : {
        palette: {
          primary: {
            main: '#1976D2',
            light: '#4791DB',
            dark: '#115293'
          },
          secondary: {
            main: '#518BAB',
            light: '#8CC4E4',
            dark: '#094F77'
          },
          error: {
            main: '#F44336',
            light: '#E57373',
            dark: '#D32F2F'
          },
          warning: {
            main: '#FFA726',
            light: '#FFB74D',
            dark: '#F57C00'
          },
          info: {
            main: '#29B6F6',
            light: '#4FC3F7',
            dark: '#0288D1'
          },
          disabled: {
            main: grey[600]
          },
          success: {
            main: '#66BB6A',
            light: '#81C784',
            dark: '#388E3C'
          },
          background: {
            default: '#fafbfd'
          },
          action: {
            // The ones we used there
            disabled: '#c2c2c2',
            selected: '#1976D2'
          }
        },
        typography: {
          fontFamily: ['Lato', 'Sans-Serif'].join(','),
          button: {
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '19.2px',
            letterSpacing: '4%'
          },
          body1: {
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '140%' // ontWeight: 300
          },
          h1: {
            fontWeight: 500,
            fontSize: '5.5rem'
          },
          h2: {
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '200%',
            fontSize: '32px',
            letterSpacing: '4%'
          },
          h4: {
            fontWeight: 800
          },
          h5: {
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '200%',
            letterSpacing: '4%'
          },
          subtitle1: {
            fontStyle: 'italic',
            fontWeight: 400,
            lineHeight: '140%',
            letterSpacing: '4%'
          }
        },
        components: {
          MuiListItemButton: {
            styleOverrides: {
              root: ({ theme }) => ({
                '&.Mui-selected': {
                  color: theme.palette.primary.main,
                  backgroundColor: theme.palette.background.paper
                }
              })
            }
          }
        }
      }
)

export default theme
