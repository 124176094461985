import { get } from 'lodash'
import {
  RecoilState,
  RecoilValueReadOnly,
  useRecoilValue,
  useSetRecoilState
} from 'recoil'
import AmalfiAsyncAutocomplete from '../../filters/AmalfiAutocomplete'
// import { AmalfiAsyncAutocomplete } from '@amalfi-analytics/components'
import useDictionary from '../../utils/useDictionary'

interface optionProps {
  code: string
  descriptor: string
}

const DICT_NAME = 'filters'

interface AutocompleteAtom {
  value: string[]
  name: string
  optionsDict: string
  dictName?: string
  dictAppId?: string
}

interface IrisAsyncAutoCompleteProps {
  atom: RecoilState<AutocompleteAtom>
  selector: RecoilValueReadOnly<AutocompleteAtom>
}

function transformKeys (
  keys: string[],
  dict: { [id: string]: string }
): Array<{
    code: string
    descriptor: string
    firstLetter: string
  }> {
  return keys
    .filter((k: string) => !k.includes('|'))
    .map((k: string) => {
      return { code: k, descriptor: get(dict, k) ?? '' }
    })
    .map((option) => {
      const firstLetter = option.code[0].toUpperCase()
      return {
        firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
        ...option
      }
    })
}

const IrisAsyncAutoComplete = ({
  atom,
  selector
}: IrisAsyncAutoCompleteProps): JSX.Element => {
  const setState = useSetRecoilState(atom)
  const state = useRecoilValue(selector)
  const filtersDict = useDictionary(
    state.dictName ?? DICT_NAME,
    state.dictAppId
  )
  const optionsDict = useDictionary(state.optionsDict, state.dictAppId)

  if (filtersDict.loading) {
    return <></>
  }

  const dictOptions = optionsDict.dictionary
  let content: { [key: string]: string } = {}
  if (dictOptions === null) {
    content = {}
  } else if ('list' in dictOptions.content) {
    content = dictOptions.content.list
  } else {
    content = dictOptions.content as any as { [key: string]: string }
  }

  return (
    <AmalfiAsyncAutocomplete
      loading={optionsDict.loading}
      options={transformKeys(Object.keys(content), content)}
      label={filtersDict.searchInDictionary(state.name)}
      getOptionLabel={(option: optionProps) =>
        `${option.code}${option.descriptor !== '' ? ':' : ''} ${
          option.descriptor
        }`}
      onChange={(_event: any, value: any) => {
        setState({ ...state, value: value.map((w: optionProps) => w.code) })
      }}
      value={transformKeys(state.value, content)}
    />
  )
}

export default IrisAsyncAutoComplete
