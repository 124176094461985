import {
  Timeseries,
  TimeseriesDouble,
  TimeseriesNow,
  OccupancyBarChart,
  Matrix,
  TimeseriesMultiple
} from '@amalfi-analytics/visualizations'
import { ContainedItem } from '../types/componentTypes'

// Import IRIS components
import FigueresCard from '../irisComponents/cards/FigueresCard'
import IrisCard from '../irisComponents/cards/IrisCard'
import IrisDoubleCard from '../irisComponents/cards/IrisDoubleCard'
import IrisTitle from '../irisComponents/helpers/IrisTitle'
import IrisActualizationMenu from '../irisComponents/helpers/IrisActualizationMenu'
import IrisServiceStatus from '../irisComponents/helpers/IrisServiceStatus'
import IrisTable from '../irisComponents/tables/IrisTable'
import amalfiVisualizationHOC from './amalfiVisualizationHOC'
import DownloadButton from '../inConstruction/DownloadButton'
import IrisPaper from '../irisComponents/helpers/IrisPaper'
import IrisMultiCard from '../irisComponents/cards/IrisMultiCard'
import IrisStackBar from '../irisComponents/visualizers/IrisStackBar'

export type { ContainedItem } from '../types/componentTypes'
export const buildQuery = (
  x: ContainedItem,
  query: object | null,
  lang: string
): {
  fn?: string | undefined
  query?: object | undefined
  source?: string | undefined
  aggregation?: object | undefined
  fn_args?: object | undefined
  lang?: string | undefined
} | null => {
  const question: {
    fn?: string
    query?: object
    source?: string
    aggregation?: object
    fn_args?: object
    lang?: string
  } = {}
  if (x.query !== undefined) {
    question.query = { type: 'ES', filters: x.query }
  } else if (x.iris_query !== undefined) {
    question.query = { type: 'iris', filters: x.iris_query }
  } else {
    if (query === null) {
      return null
    }
    question.query = query
  }
  question.fn = x.fn
  if (x.source !== undefined) {
    question.source = x.source
  }
  if (x.aggs != null) {
    question.aggregation = x.aggs
  }
  if (x.fn_args != null) {
    question.fn_args = x.fn_args
  }
  question.lang = lang
  return question
}

export const componentsRepository: { [key: string]: any } = {
  // MultipleCard,
  Matrix: amalfiVisualizationHOC(Matrix),
  OccupancyBarChart: amalfiVisualizationHOC(OccupancyBarChart),
  Timeseries: amalfiVisualizationHOC(Timeseries),
  TimeseriesDouble: amalfiVisualizationHOC(TimeseriesDouble),
  TimeseriesNow: amalfiVisualizationHOC(TimeseriesNow),
  TimeseriesMultiple: amalfiVisualizationHOC(TimeseriesMultiple),
  DownloadButton,
  IrisActualizationMenu,
  IrisServiceStatus,
  FigueresCard,
  IrisTitle,
  IrisTable,
  IrisCard,
  IrisDoubleCard,
  IrisMultiCard,
  IrisPaper,
  IrisStackBar
}
