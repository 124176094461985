import { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import {
  dashboardsAtom,
  hasValidSession,
  useUserId
} from './components/authentification/authState'
import { refreshDashboards } from './components/authentification/authUtils'
import { setMetadata } from './components/utils/host'
import IrisLogin from './components/authentification/IrisLogin'
import Main from './components/dashboard/Main'
import { ErrorBoundaryApp } from './components/errors/ErrorBoundary'
import Loading from './components/irisComponents/helpers/Loading'
import Box from '@mui/material/Box'
import ErrorLoadingDashboards from './components/irisComponents/helpers/ErrorLoadingDashboards'
import NoDashboardsAvailable from './components/irisComponents/helpers/NoDashboardsAvailable'
import Cookies from 'universal-cookie'
import useMount from 'react-use/lib/useMount'
import { PostHogProvider, usePostHog } from 'posthog-js/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false
    }
  }
})

function InnerApp (): JSX.Element {
  const validSession = hasValidSession()
  const [dashboard, setDashboardAtom] = useRecoilState(dashboardsAtom)
  const [errorLoading, setErrorLoading] = useState(false)
  const posthog = usePostHog()
  const userId = useUserId()

  useEffect(() => {
    if (validSession && dashboard === null) {
      refreshDashboards(setDashboardAtom)
        .catch((e) => {
          console.error('Error loading dashboards', e)
          setErrorLoading(true)
        })
        .finally(() => {})
    }
  }, [validSession, dashboard, setDashboardAtom])

  useEffect(() => {
    if (userId !== '') {
      posthog.identify(userId)
    }
  }, [posthog, userId])

  if (!validSession) {
    return <IrisLogin />
  }

  if (errorLoading) {
    return (
      <Box height={100} width='100%'>
        <ErrorLoadingDashboards />
      </Box>
    )
  }
  if (dashboard === null && validSession) {
    return (
      <>
        <Loading />
      </>
    )
  }
  if (dashboard === null || dashboard.length === 0) {
    return (
      <Box height={100} width='100%'>
        <NoDashboardsAvailable />
      </Box>
    )
  }

  return (
    <Box height={100} width='100%'>
      <Main />
    </Box>
  )
}

const setLocaleCookie = (): void => {
  const params = new URLSearchParams(window.location.search)
  const locale = params.get('locale')
  const cookie = new Cookies()
  const cookieLocale = cookie.get('app.locale')
  if (locale !== null && locale !== cookieLocale) {
    cookie.set('app.locale', locale)
  }
}

function App (): JSX.Element {
  useMount(() => {
    setLocaleCookie()
    setMetadata()
  })

  return (
    <ErrorBoundaryApp>
      <QueryClientProvider client={queryClient}>
        <PostHogProvider
          // @ts-expect-error
          apiKey={window.CONFIG.POSTHOG_API_KEY}
          options={{
            // @ts-expect-error
            api_host: window.CONFIG.POSTHOG_HOST,
            disable_persistence: true
          }}
        >
          <InnerApp />
        </PostHogProvider>
      </QueryClientProvider>
    </ErrorBoundaryApp>
  )
}

export default App
